// third-party
// assets
import {
    IconBell,
    IconBoxMultiple,
    IconBuildingCommunity,
    IconBuildingStore,
    IconBulb,
    IconCirclePlus,
    IconClipboardList,
    IconDashboard,
    IconFileCode,
    IconFileText,
    IconGift,
    IconHelp,
    IconSchool,
    IconSettings,
    IconSitemap,
    IconTag,
    IconUpload,
    IconUser,
    IconUsers
} from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

// constant
const icons = {
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconUser,
    IconFileCode,
    IconBuildingCommunity,
    IconClipboardList,
    IconUpload,
    IconCirclePlus,
    IconBulb,
    IconBoxMultiple,
    IconFileText,
    IconTag,
    IconSettings,
    IconGift,
    IconBuildingStore,
    IconSchool
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: '',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'item',
            title: <FormattedMessage id="createItem" />,
            type: 'item',
            url: '/item/create',
            icon: icons.IconCirclePlus,
            breadcrumbs: false
        },
        {
            id: 'items',
            title: <FormattedMessage id="itemList" />,
            type: 'item',
            url: '/items',
            icon: icons.IconBulb,
            breadcrumbs: false
        },
        {
            id: 'notifications',
            title: <FormattedMessage id="notification" />,
            type: 'item',
            url: '/notifications',
            icon: IconBell,
            breadcrumbs: false
        },
        {
            id: 'popup',
            title: <FormattedMessage id="popup" />,
            type: 'item',
            url: '/popup',
            icon: icons.IconBoxMultiple,
            breadcrumbs: false
        },
        {
            id: 'my-files',
            title: <FormattedMessage id="myFiles" />,
            type: 'item',
            url: '/my-files',
            icon: icons.IconFileText,
            breadcrumbs: false
        },
        {
            id: 'user_management',
            title: <FormattedMessage id="userManagement" />,
            type: 'item',
            url: '/users',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'pricing_plan',
            title: <FormattedMessage id="pricingPlan" />,
            type: 'item',
            url: '/pricing-plan',
            icon: icons.IconTag,
            breadcrumbs: false
        },
        {
            id: 'shop-item',
            title: <FormattedMessage id="shopItem" />,
            type: 'item',
            url: '/shop-item',
            icon: icons.IconBuildingStore,
            breadcrumbs: false
        },
        {
            id: 'achievement',
            title: <FormattedMessage id="globalAchievement" />,
            type: 'item',
            url: '/achievement/',
            icon: icons.IconGift,
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'item',
            url: '/settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        },
        {
            id: 'class-request',
            title: <FormattedMessage id="class-request" />,
            type: 'item',
            url: '/class-request',
            icon: icons.IconSchool,
            breadcrumbs: false
        }
    ]
};
export const superUser = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'user-create',
            title: <FormattedMessage id="User Create" />,
            type: 'item',
            url: '/super-user/users-create',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'acl',
            title: <FormattedMessage id="ACL" />,
            type: 'item',
            url: '/super-user/acl',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'forum-acl',
            title: <FormattedMessage id="Forum ACL" />,
            type: 'item',
            url: '/super-user/forums',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default other;
